import { css, html, LitElement } from "lit";
import { url } from "../lit-directives.js";
import { classMap } from "lit/directives/class-map.js";
import close_icon_transparent_background from './images/close-icon-transparent-background.svg';
import info_symbol from './images/info-symbol.svg';
import { isSmartPhone, isSmartPhoneBrowser, isOffshoreApp } from "../../platform.js";

const
    gettext = window.gettext,
    active_title = gettext("Measure Distance"),
    active_subtitle = gettext("Total Distance"),
    active_help = gettext("Click on the map to add to path"),
    active_help_mobile = gettext("Drag & Tap Add Point"),
    add_point_text = gettext("+ Add Point"),
    remove_point_text = gettext("Remove last point"),
    end_ruler_text = gettext("End"),
    end_measure_text = gettext("Press Enter to end measurement");

export default class PWMapRulerButton extends LitElement {
    static get properties() {
        return {
            open: { type: Boolean, reflect: true },
            distance: { type: Number },
            tempDistance: { type: Number },
            distanceUnit: { type: String },
            showEndPrompt: { type: Boolean },
            shifted: {type: Boolean }
        };
    }

    constructor() {
        super();
        this.open = false;
        this.distance = 0;
        this.tempDistance = 0;
        this.distanceUnit = "nm";
        this.showEndPrompt = true;
        this.shifted = false;
    }

    updated(changedProperties) {
        if (changedProperties.has("open") && (isSmartPhone || isSmartPhoneBrowser)) {
            $j('.mapPredictWindLogo').prop('hidden', !!this.open);
            $j('pw-map-slider').prop('timestampHidden', !!this.open);
        }
        super.updated(changedProperties);
    }

    addPoint() {
        this.dispatchEvent(new CustomEvent('add-point'));
    }

    removePoint() {
        this.dispatchEvent(new CustomEvent('remove-point'));
    }

    endRuler() {
        this.dispatchEvent(new CustomEvent('end-ruler'));
    }

    disableRuler(event) {
        this.open = false;
        if (PWGMap.ruler) {
            if (PWGMap.ruler.enabled) {
                PWGMap.ruler.disable();
            } else if (PWGMap.ruler2.enabled) {
                PWGMap.ruler2.disable();
            }
        }
        this.dispatchEvent(new CustomEvent('ruler-disabled'));
        event.stopPropagation();
    }

    render() {
        return html`
            <div class="${classMap({ "ruler-button-wrapper": true, "shifted": this.shifted}) }">
                <div class="${classMap({ "ruler-control-wrapper": true, "ruler-control-wrapper-active": this.open })}">
                    <div class="ruler-control" @click="${this.removePoint}">${remove_point_text}</div>
                    <div class="ruler-control" @click="${this.addPoint}">${add_point_text}</div>
                    <div class="ruler-control" @click="${this.endRuler}">${end_ruler_text}</div>
                </div>
                <div class="${classMap({ "ruler-button": true, "ruler-button-active": this.open })}" @click="${this.disableRuler}">
                    <span class="close"></span>
                    <div class="ruler-button-title">${active_title}</div>
                    <div class="ruler-distance">
                        <span>${active_subtitle}</span><span>${(this.distance + this.tempDistance).toFixed(2) + this.distanceUnit}</span>
                    </div>
                    <div class="ruler-button-help">${isSmartPhone || isSmartPhoneBrowser ? active_help_mobile : active_help}</div>
                    <div class="${classMap({ "end-measure-prompt": true, "active-flex": this.open && this.showEndPrompt })}">
                        <span class="info-symbol"></span> ${end_measure_text}
                    </div>
                </div>
            </div>
        `
    }

    static get styles() {
        const s = [css`
            .ruler-button-wrapper {
                position:absolute;
                width:100%;
                top:0;
                left:0;
                z-index:99;
                display:flex;
                justify-content:center;
                pointer-events: none;
                user-select: none;
                margin-top: 6px;
            }

            .ruler-button {
                background:rgba(26, 47, 67, 0.9);
                border-radius:8px;
                color:white;
                padding:10px;
                cursor:pointer;
                width: 220px;
                pointer-events: auto;
                display: none;
                position: relative;
            }

            .ruler-button-active {
                display: block;
            }

            .ruler-button-title {
                font-weight: 700;
                font-size: 18px;
                margin-bottom:5px;
            }

            .ruler-distance {
                display: flex;
                justify-content: start;
                margin-bottom: 5px
            }

            .ruler-distance > span {
                width: 50%;
                font-size: 14px;
            }

            .ruler-distance>span:last-child {
                font-weight: 700;
            }

            .ruler-button-help {
                opacity: 0.5;
                font-size: 12px;
            }

            .close {
                background: ${url(close_icon_transparent_background)} no-repeat center;
                background-size: 10px;
                display: inline-block;
                position: relative;
                top: 0px;
                right: 0px;
                width: 10px;
                height: 10px;
                float: right;
            }

            .ruler-control-wrapper {
                display:none;
            }

            .end-measure-prompt {
                display: none;
                background: rgba(26, 47, 67, 0.50);
                border-radius: 8px;
                position: absolute;
                top: calc(100% + 8px);
                right: 0px;
                left: 0px;
                color: #FFFFFF;
                font-size: 13px;
                font-weight: 500;
                padding: 11px 9px;
                pointer-events: none;
                justify-content: center;
            }
            .end-measure-prompt.active-flex {
                display: flex;
            }
            .info-symbol {
                display: inline-block;
                background: ${url(info_symbol)} no-repeat center;
                background-size: 14px;
                width: 14px;
                height: 14px;
                margin-right: 7px;
            }
        `
        ];

        if (isSmartPhone || isSmartPhoneBrowser) {
            s.push(
                css`/*Smartphone*/
                    .ruler-button-wrapper {
                        bottom: 48px;
                        left: 0;
                        top: auto;
                        padding: 9px;
                        box-sizing: border-box;
                        display: block;
                    }

                    .ruler-button {
                        width: 100%;
                        text-align: left;
                        box-sizing: border-box;
                        margin-top: auto;
                    }

                    .ruler-button * {
                        font-weight: 400;
                    }

                    .ruler-distance > span {
                        width: 30%;
                    }

                    .ruler-control-wrapper {
                        display: none;
                        width: 100%;
                        justify-content: end;
                        margin-bottom: 5px;
                        background: none;
                        justify-content: space-between;
                        gap: 7px;
                    }

                    .ruler-control-wrapper-active {
                        display: flex;
                    }

                    .ruler-control {
                        color: white;
                        font-size: 16px;
                        font-weight: 600;
                        padding: 14px 13px;
                        background: rgba(26, 47, 67, 0.7);
                        border-radius: 10px;
                        pointer-events: auto;
                        flex-grow: 1;
                    }

                    .end-measure-prompt.active-flex {
                        display: none;
                    }
                `
            )
            if (isOffshoreApp) {
                s.push(
                    css`
                        .ruler-button-wrapper {
                            bottom: 60px;
                        }
                    `
                )
            } else if ($j('.track-display-right-column').length) {
                s.push(
                    css`
                        .ruler-button-wrapper {
                            bottom: 135px
                        }
                    `
                )

                if ($j(".public").length) {
                    s.push(
                        css`
                            .ruler-button-wrapper {
                                left: 50px;
                                width: calc(100% - 50px);
                            }
                        `
                    )
                }
            }
        } else if (isOffshoreApp) {
            s.push(
                css`
                    .ruler-button-wrapper.shifted {
                        top: 55px;
                    }
                `
            )
        }
        return s;
    }
}

customElements.define('pw-map-ruler-button', PWMapRulerButton);