export default function processNewLocation(event) {
    // Add new location to location menu on desktop forecast site
    // Note .clone(true, true) make a deep copy of existing element with handlers
    // it ALSO includes data which we are not after, but the confirm buttons
    // don't have their own data anyway.
    // Also note anchor.data(location) would be nice but does not reflect back to attributes,
    // and we use some data-* attributes for selectors elsewhere.
    const
        location = event.detail.newLocation,
        delete_confirm = $j('.loc-del-confirm').first().clone(true, true),
        anchor = $j('<a>', {
            "class": "location",
            "data-id": location.id,
            "data-paid": location.paid,
            "data-lat": location.lat,
            "data-lon": location.lon
        }).text(location.name),
        button = $j('<div>', {"class": "delete-location show-del-confirm"}),
        new_item = $j('<li>', {title: location.name})
            .append(anchor, button);
    $j('#location-menu').append(new_item, delete_confirm);
    button.on('click', event => {
        event.stopPropagation(); // don't bubble up to li and select a location!
        new_item.add(delete_confirm).addClass("active");
        setTimeout(function() {
            delete_confirm.children('.delete-location.yes').addClass('slide');
        }, 50);
    });
    new_item.on('click', function (event) {
        let currentLocation = JSON.parse(window.pwStorage.getItem('currentLocation'));
        if (!currentLocation || currentLocation.id != location.id) {
            pw_setCurrentLocation(location);
            window.pw_updatePageForNewLocation?.(location);
        }
    });
    window.pw_setCurrentLocation(location);
    window.pw_updatePageForNewLocation?.(location);
}
